<!-- 首页 -->
<template>
    <div class="home-page">
        <!-- 轮播图开始 -->
        <div class="carousel">
            <el-carousel id="carousel" arrow="never">
                <el-carousel-item v-for="(item, index) in carouselList" :key="index">
                    <div class="el-carousel-item">
                        <div class="el-carousel-item-font">
                            <div class="first-paragraph">
                                <h2>{{ item.h2 }}</h2>
                                <h2>{{ item.mark }}</h2>
                            </div>
                            <h4>{{ item.h4 }}</h4>
                            <div style="display: inline-block">
                                <div class="more-details" @click="toDetails(item.path, index)">
                                    <p>了解详情</p>
                                    <img src="../../assets/carousel/path-g@3x.png" alt="" />
                                </div>
                            </div>
                        </div>
                        <div class="el-carousel-item-img">
                            <img v-lazy="item.img" alt="" />
                        </div>
                    </div>
                </el-carousel-item>
            </el-carousel>
            <p style="line-height: 50px"><br /></p>
        </div>
        <!-- 轮播图结束 -->
        <!-- 数量展示开始 -->
        <div class="numbers-display">
            <ul>
                <li v-for="(item, index) in numbersDisplayList" :key="index">
                    <img v-lazy="item.img" alt="" />
                    <div>
                        <h2>{{ item.h2 }}</h2>
                        <h4>{{ item.h4 }}</h4>
                    </div>
                </li>
            </ul>
        </div>
        <!-- 数量展示结束 -->

        <!-- 解决方案开始 -->
        <div class="solution">
            <div class="title">
                <h4>平正云</h4>
                <h4>可以为您提供哪些解决方案？</h4>
            </div>
            <div class="content">
                <ul>
                    <li v-for="(item, index) in solutionList" :key="index">
                        <img v-lazy="item.img" alt="" class="pics" />
                        <div>
                            <h2>{{ item.h2 }}</h2>
                            <h4>{{ item.h4 }}</h4>
                        </div>
                        <p class="content-more" @click="toDetails(item.path)">
                            <span>更多详情</span>
                            <img src="../../assets/solution/path-b@3x.png" alt="" />
                        </p>
                    </li>
                </ul>
            </div>
            <div class="more" @click="more('/solution')"><span>查看全部</span></div>
        </div>
        <!-- 解决方案结束 -->

        <!-- 客户肯定开始 -->
        <div class="Customer-affirmation">
            <div class="title">来自客户的肯定</div>
            <div class="carousel2">
                <el-carousel height="27rem" arrow="never" id="el-carousel">
                    <el-carousel-item
                        class="el-carousel-item"
                        v-for="(item, index) in CustomerAffirmationList"
                        :key="index"
                    >
                        <div class="item-details" v-for="(item1, index1) in item.children" :key="index1">
                            <img v-lazy="item1.img" alt="" class="pics" />
                            <p>{{ item1.details }}</p>
                            <span>{{ item1.managerWord }}</span>
                        </div>
                    </el-carousel-item>
                </el-carousel>
            </div>
        </div>
        <div class="Customer-affirmation-mobile">
            <h2>来自客户的肯定</h2>
            <div class="container">
                <div class="el-carousel-item" v-for="(item, index) in CustomerAffirmationList" :key="index">
                    <div class="item-details" v-for="(item1, index1) in item.children" :key="index1">
                        <img v-lazy="item1.img" alt="" class="pics" />
                        <p>{{ item1.details }}</p>
                        <span>{{ item1.managerWord }}</span>
                    </div>
                </div>
            </div>
        </div>
        <!-- 客户肯定结束 -->
        <!-- Customer wall  客户墙 开始-->
        <div class="customer-wall">
            <div class="title">
                <p>
                    <span>千万客户携手</span>
                    <span>平正云</span>
                </p>
                <p>已完成数字化建设</p>
            </div>
            <div class="pics-wall">
                <img v-for="(item, index) in customerWall" :key="index" v-lazy="item.img" alt="" />
            </div>
        </div>
        <!-- Customer wall  客户墙 结束-->

        <!-- information submitted 信息提交开始 -->
        <!--div class="information-submitted">
            <div class="title">
                <h2>HI，认识一下！</h2>
                <p>
                    如需更深入了解平正云，请填写以下信息，我们会尽快安排专业人员与您沟通，帮助您快速建立企业数据解决方案。
                </p>
            </div>
            <div class="content">
                <div class="content-input">
                    <input type="text" placeholder="姓名：" v-model="submitMsg.userName" />
                    <input type="number" placeholder="联系方式：" v-model="submitMsg.mobilePhone" />
                </div>
                <div class="content-textarea">
                    <textarea placeholder="留言给我们" v-model="submitMsg.remark"></textarea>
                </div>
            </div>
            <div class="submit" @click="submit()">提交留言</div>
        </div-->
        <!-- information submitted 信息提交结束 -->
        <!-- 二维码 -->
        <contactUs ref="contactUs"></contactUs>
        <!-- 移动端二维码 -->
    </div>
</template>

<script>
import contactUs from "@/components/contactUs/contactUs";
import { mapState } from "vuex";
import { myMixins } from "@/utils/mixin/mixin.js";
export default {
    mixins: [myMixins],
    components: { contactUs },
    data() {
        return {
            carouselList: [
                {
                    img: require("../../assets/carousel/pic1@3x.png"),
                    h2: "数据",
                    mark: "可视化",
                    h4: "帮助用户实现数据收集、数据整合、决策分析",
                    details: "了解详情",
                    path: "/solution2",
                },
                {
                    img: require("../../assets/carousel/pic2@3x.png"),
                    h2: "数字化",
                    mark: "转型咨询",
                    h4: "利用数字化技术和能力，驱动企业商业生态重构",
                    details: "了解详情",
                    path: "",
                },
                {
                    img: require("../../assets/carousel/pic3@3x.png"),
                    h2: "数字化",
                    mark: "调研平台",
                    h4: "从项目委托到数据交付可全流程自动化",
                    details: "了解详情",
                    path: "",
                },
                {
                    img: require("../../assets/carousel/pic4@3x.png"),
                    h2: "标准化",
                    mark: "社会治理",
                    h4: "构建社会治理新格局，推动社会治理和服务重心向基层下移",
                    details: "了解详情",
                    path: "/solution",
                },
            ], //轮播图内容
            numbersDisplayList: [
                {
                    img: require("../../assets/numbers-display/1@3x.png"),
                    h2: "19+",
                    h4: "软件专利",
                },
                {
                    img: require("../../assets/numbers-display/2@3x.png"),
                    h2: "20+",
                    h4: "合作伙伴",
                },
                {
                    img: require("../../assets/numbers-display/3@3x.png"),
                    h2: "16+",
                    h4: "涉及行业",
                },
                {
                    img: require("../../assets/numbers-display/4@3x.png"),
                    h2: "50+",
                    h4: "用户量(万)",
                },
            ], //数量展示
            solutionList: [
                {
                    img: require("../../assets/solution/1@3x.png"),
                    h2: "数据可视化",
                    h4: "以最简单的方式观察数据",
                    details: "",
                    path: "/solution2",
                },
                {
                    img: require("../../assets/solution/2@3x.png"),
                    h2: "场景解决方案",
                    h4: "为各种场景提供相关方案",
                    details: "",
                    path: "/solution",
                },
                {
                    img: require("../../assets/solution/3@3x.png"),
                    h2: "行业解决方案",
                    h4: "为多个行业提供相关方案",
                    details: "",
                    path: "/solution3",
                },
                {
                    img: require("../../assets/solution/4@3x.png"),
                    h2: "平台解决方案",
                    h4: "提供平台解决方案",
                    details: "",
                    path: "/product",
                },
            ], //解决方案展示
            CustomerAffirmationList: [
                {
                    children: [
                        {
                            img: require("../../assets/Customer-affirmation/kh1@3x.jpg"),
                            details: "从理论上剖析了社会治理公共服务的定义、标准、范围以及企业参与社会治理公共服务的科学依据，为乡村振兴创新实践提供了新路径。",
                            managerWord: "保利物业客户服务部经理",
                        },
                        {
                            img: require("../../assets/Customer-affirmation/kh2@3x.jpg"),
                            details: "构建包含题库设计、学生报名、数据采集、进度管理、数据处理等环节的全链数字化调研管理方案，为“千村调查”研究工作提供有力的平台支撑。",
                            managerWord: "上海财经大学三农研究院",
                        },
                        {
                            img: require("../../assets/Customer-affirmation/kh3@3x.6f46b.jpg"),
                            details: "有效提升物业各部门的工作效率，实现各部门移动化、流程化、标准化；实现管理运营数据化，项目管控集约化，绩效考核透明化。",
                            managerWord: "招商物业项目经理",
                        },
                    ],
                },
                {
                    children: [
                        {
                            img: require("../../assets/Customer-affirmation/kh1@3x.jpg"),
                            details: "从理论上剖析了社会治理公共服务的定义、标准、范围以及企业参与社会治理公共服务的科学依据，为乡村振兴创新实践提供了新路径。",
                            managerWord: "保利物业客户服务部经理",
                        },
                        {
                            img: require("../../assets/Customer-affirmation/kh2@3x.jpg"),
                            details: "构建包含题库设计、学生报名、数据采集、进度管理、数据处理等环节的全链数字化调研管理方案，为“千村调查”研究工作提供有力的平台支撑。",
                            managerWord: "上海财经大学三农研究院",
                        },
                        {
                            img: require("../../assets/Customer-affirmation/kh3@3x.6f46b.jpg"),
                            details: "有效提升物业各部门的工作效率，实现各部门移动化、流程化、标准化；实现管理运营数据化，项目管控集约化，绩效考核透明化。",
                            managerWord: "招商物业项目经理",
                        },
                    ],
                },
                {
                    children: [
                        {
                            img: require("../../assets/Customer-affirmation/kh1@3x.jpg"),
                            details: "从理论上剖析了社会治理公共服务的定义、标准、范围以及企业参与社会治理公共服务的科学依据，为乡村振兴创新实践提供了新路径。",
                            managerWord: "保利物业客户服务部经理",
                        },
                        {
                            img: require("../../assets/Customer-affirmation/kh2@3x.jpg"),
                            details: "构建包含题库设计、学生报名、数据采集、进度管理、数据处理等环节的全链数字化调研管理方案，为“千村调查”研究工作提供有力的平台支撑。",
                            managerWord: "上海财经大学三农研究院",
                        },
                        {
                            img: require("../../assets/Customer-affirmation/kh3@3x.6f46b.jpg"),
                            details: "有效提升物业各部门的工作效率，实现各部门移动化、流程化、标准化；实现管理运营数据化，项目管控集约化，绩效考核透明化。",
                            managerWord: "招商物业项目经理",
                        },
                    ],
                },
            ], //客户肯定展示
            customerWall: [
                { img: require("../../assets/pics-wall/1 (1).png") },
                { img: require("../../assets/pics-wall/1 (2).png") },
                { img: require("../../assets/pics-wall/1 (3).png") },
                { img: require("../../assets/pics-wall/1 (4).png") },
                { img: require("../../assets/pics-wall/1 (5).png") },
                { img: require("../../assets/pics-wall/1 (6).png") },
                { img: require("../../assets/pics-wall/1 (7).png") },
                { img: require("../../assets/pics-wall/1 (8).png") },
                { img: require("../../assets/pics-wall/1 (9).png") },
                { img: require("../../assets/pics-wall/1 (10).png") },
            ], //照片墙
            submitMsg: {}, //信息提交
        };
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {
        // 预约试用提交信息
        submit() {
            let reg_user = /^[\u4e00-\u9fa5]{2,4}$/; //2-4个中文字符正则
            let reg_tel = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/; //11位手机号码正则

            if (Object.keys(this.submitMsg).length == 0) {
                this.$message({
                    message: "请输入正确的信息",
                    type: "error",
                });
                return;
            }
            if (!reg_user.test(this.submitMsg.userName)) {
                this.$message({
                    message: "请输入正确的姓名！",
                    type: "error",
                });
                return;
            } else if (!reg_tel.test(this.submitMsg.mobilePhone)) {
                this.$message({
                    message: "请输入正确的11位电话号码！",
                    type: "error",
                });
                return;
            }
            this.$post("officewebUser/save", this.submitMsg)
                .then((res) => {
                    if (res.data.code == 0) {
                        this.$message({
                            message: "提交成功！",
                            type: "success",
                        });
                        this.submitMsg = {};
                    } else {
                        this.$message({
                            message: "提交失败！请稍后再试",
                            type: "error",
                        });
                    }
                })
                .catch((err) => {
                    console.log(err);
                    this.$message({
                        message: "提交失败！请稍后再试",
                        type: "error",
                    });
                });
        },
        // 轮播图跳转
        toDetails(e, index) {
            this.$router.push({ path: e });
            // 第二三张轮播图 不跳转显示联系我们二维码
            if ([1, 2].includes(index)) {
                this.qrCode();
            }
        },
        more(e) {
            this.$router.push({ path: e });
        },
    },
};
</script>

<style scoped lang="scss">
@media screen and (min-width: 751px) {
    @import "index_PC.scss";
}
@media screen and (max-width: 750px) {
    @import "index_mobile.scss";
}
</style>
